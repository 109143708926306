import React, { useEffect, useState } from "react";
import {
	Typography,
	Button,
	Stack,
	Card,
	Box,
	FormControl,
	FormLabel,
	TextField,
	Select,
	MenuItem,
	Drawer,
	Modal,
	InputLabel,
	Switch,
	IconButton,
	InputAdornment,
	FormHelperText,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import typography from "src/theme/typography";
import Iconify from "src/components/iconify/Iconify";
import { useNavigate } from "react-router-dom";
import Search from "src/components/search/Search";
import {
	createPlan,
	deletePlan,
	disablePlan,
	getSubscriptionPlan,
	getSubscriptionPlanById,
	updatePlan,
} from "src/features/subscriptionPlan/subscriptionPlanApi";
import { styled } from "@mui/material/styles";
import palette from "src/theme/palette";
import { getUserInfo } from "src/features/user/userState";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { useForm } from "react-hook-form";
import AccountPopover from "src/layouts/dashboard/header/AccountPopover";
import { Helmet } from "react-helmet-async";
import {
	decrementAwaitingApiCounter,
	incrementAwaitingApiCounter,
} from "src/features/ui/uiState";

const SubscriptionPlans = () => {
	const userInfo = useAppSelector(getUserInfo);
	const dispatch = useAppDispatch();

	const [isModalActive, setIsModalActive] = useState(false);
	const [isStateChange, setIsStateChange] = useState(false);
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [pricePerOrder, setPricePerOrder] = useState("");
	const [pricePerReturn, setPricePerReturn] = useState("");
	const [duration, setDuration] = useState("");
	const [billingCycle, setBillingCycle] = useState("Monthly");
	const [isVisible, setIsVisible] = useState(false);
	const [createIsVisible, setCreateIsVisible] = useState(false);
	const [subHeader, setSubHeader] = useState("");
	const [subscriptionPlans, setSubscriptionPlans] = useState([]);
	const [isOpen, setOpen] = useState(false);
	const [error, setError] = useState(false);
	const [showSuccessModel, setShowSuccessModel] = useState(false);
	const [showUpdateModel, setShowUpdateModel] = useState(false);
	const [showDeleteModel, setShowDeleteModel] = useState(false);
	// const [isDisableplan, setIsDisableplan] = useState(true);
	const [planErrors, setPlanErrors] = useState({
		name: false,
		description: false,
		billingCycle: false,
		pricePerOrder: false,
		duration: false,
		subHeader: false,
		pricePerReturn:false
	});

	const [updatePlanErrors, setUpdatePlanErrors] = useState({
		name: false,
		description: false,
		billing_cycle: false,
		price_per_order: false,
		duration: false,
		sub_header: false,
		price_per_return: false
	});

	const [drawerData, setDrawerData] = useState({});
	const [searchPlan, setSearchPlan] = useState("");
	const [isOnDelete, setIsOnDelete] = useState(false);

	const handleSearchPlan = (e) => {
		e.preventDefault();
		setSearchPlan(e.target.value);
		if (e.target.value === "") {
			setIsStateChange(!isStateChange);
		}
		let filteredRows = subscriptionPlans.filter((row) =>
			row.name.trim().toLowerCase().includes(e.target.value.toLowerCase())
		);
		if (filteredRows.length > 0) {
			setSearchPlan(e.target.value);
			setSubscriptionPlans(filteredRows);
		}
	};

	const popUpModalStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "80%", sm: "auto", lg: "auto", md: "auto" },
		borderRadius: "20px",
		background: "#FFF",
		overflow: "auto",
		overflow: "auto-y",
		overflowX: "hidden",
		outline: "none",
	};

	const DeleteButton = styled(Button)({
		backgroundColor: "#AC2D15",
	});

	const textStyle = {
		color: palette.common.black,
	};

	const handleDeletePlan = async (e) => {
		e.preventDefault();
		dispatch(incrementAwaitingApiCounter());
		try {
			await deletePlan(drawerData._id, userInfo)
				.then((r) => {
					const { statusCode } = r;
					if (statusCode === 200) {
						setIsOnDelete(false);
					}
				})
				.catch((e) => {
					console.log(e);
					dispatch(decrementAwaitingApiCounter());
				});
			getSubscriptionPlan(userInfo.accessToken)
				.then((r) => {
					const { statusCode, data } = r;
					if (statusCode === 200) {
						setIsOnDelete(false);
						setShowDeleteModel(true);
						setSubscriptionPlans(data);
						dispatch(decrementAwaitingApiCounter());
					}
				})
				.catch((err) => {
					setError(true);
					dispatch(decrementAwaitingApiCounter());
				});
			setOpen(false);
		} catch (err) {
			dispatch(decrementAwaitingApiCounter());
		}
	};

	const handleCreatePlan = async (e) => {
		e.preventDefault();
		if (
			name === "" &&
			description === "" &&
			billingCycle === "" &&
			duration === "" &&
			pricePerOrder === "" &&
			subHeader === "" &&
			pricePerReturn===""
		) {
			setPlanErrors({
				name: true,
				description: true,
				billingCycle: true,
				duration: true,
				pricePerOrder: true,
				subHeader: true,
				pricePerReturn:true
			});
			return;
		}
		// if(name === '' || subHeader === '' || pricePerOrder === '' || billingCycle === '' || description === ''){
		if (name === "") {
			setPlanErrors({
				...planErrors,
				name: true,
			});
			return;
		}

		if (subHeader === "") {
			setPlanErrors({
				...planErrors,
				subHeader: true,
			});
			return;
		}

		if (pricePerOrder === "") {
			setPlanErrors({
				...planErrors,
				pricePerOrder: true,
			});
			return;
		}

		if (pricePerReturn === "") {
			setPlanErrors({
				...planErrors,
				pricePerReturn: true,
			});
			return;
		}

		if (billingCycle === "") {
			setPlanErrors({
				...planErrors,
				billingCycle: true,
			});
			return;
		}

		if (duration === "") {
			setPlanErrors({
				...planErrors,
				duration: true,
			});
			return;
		}

		if (description === "") {
			setPlanErrors({
				...planErrors,
				description: true,
			});
			return;
		}

		const headers = {
			tenant_id: userInfo.tenant_id,
			user_id: userInfo.user_id,
		};
		const data = {
			name: name,
			description: description,
			billing_cycle: billingCycle,
			duration: Number(duration),
			sub_header: subHeader,
			price_per_order: pricePerOrder,
			display: createIsVisible,
			price_per_return:pricePerReturn
		};

		let response;
		dispatch(incrementAwaitingApiCounter());
		try {
			response = await createPlan(headers, data, userInfo);
			if (response.data) {
				setShowSuccessModel(true);
				getSubscriptionPlan(userInfo.accessToken)
					.then((r) => {
						const { statusCode, data } = r;
						if (statusCode === 200) setSubscriptionPlans(data);
					})
					.catch((err) => {
						setError(true);
						dispatch(decrementAwaitingApiCounter());
					});
				setIsModalActive(false);

				data = {
					name: "",
					description: "",
					billing_cycle: "",
					duration: "",
					sub_header: "",
					price_per_order: "",
					price_per_return:""
				};
			}
			dispatch(decrementAwaitingApiCounter());
		} catch (err) {
			// setIsError(true);
			dispatch(decrementAwaitingApiCounter());
			// setStatusMessage(err.message);
		}
	};

	const handleUpdatePlan = async (e) => {
		e.preventDefault();
		dispatch(incrementAwaitingApiCounter());
		const headers = {
			tenant_id: userInfo.tenant_id,
			user_id: userInfo.user_id,
		};
		const data = {
			name: drawerData.name,
			description: drawerData.description,
			billing_cycle: drawerData.billing_cycle,
			duration: Number(drawerData.duration),
			// "price_per_order": drawerData.price_per_order.match(/(\d+)/)[0],
			price_per_order: drawerData.price_per_order,
			sub_header: drawerData.sub_header,
			display: isVisible,
			price_per_return:drawerData.price_per_return
		};
		const errors = {};

		Object.keys(data).forEach((key) => {
			if (key !== "display" && !data[key]) {
				errors[key] = true;
			}
		});

		if (Object.keys(errors).length > 0) {
			setUpdatePlanErrors(errors);
			dispatch(decrementAwaitingApiCounter());
			return;
		}
		let response;
		try {
			response = await updatePlan(
				headers,
				data,
				userInfo,
				drawerData._id
			);
			setShowUpdateModel(true);
			setIsStateChange(!isStateChange);
			setIsModalActive(false);
			setOpen(false);
			setUpdatePlanErrors({});
			dispatch(decrementAwaitingApiCounter());
		} catch (err) {
			// setIsError(true);
			dispatch(decrementAwaitingApiCounter());
			// setStatusMessage(err.message);
		}
	};

	const handleDisablePlan = async (e) => {
		e.preventDefault();
		dispatch(incrementAwaitingApiCounter());

		const data = {
			disable: true,
		};

		try {
			await disablePlan(drawerData._id, userInfo, data)
				.then((r) => {
					const { statusCode } = r;
					if (statusCode === 200) {
					}
				})
				.catch((e) => console.log(e));
			// setIsDisableplan(false)
			setIsStateChange(!isStateChange);
			setIsModalActive(false);
			setOpen(false);
			dispatch(decrementAwaitingApiCounter());
		} catch (err) {
			dispatch(decrementAwaitingApiCounter());
		}
	};

	const handleEnablePlan = async (e) => {
		e.preventDefault();
		dispatch(incrementAwaitingApiCounter());
		const data = {
			disable: false,
		};
		try {
			let response = await disablePlan(drawerData._id, userInfo, data)
				.then((r) => {
					const { statusCode } = r;
					if (statusCode === 200) {
					}
				})
				.catch((e) => console.log(e));
			// setIsDisableplan(true)
			setIsStateChange(!isStateChange);
			setIsModalActive(false);
			setOpen(false);
			dispatch(decrementAwaitingApiCounter());
		} catch (err) {
			dispatch(decrementAwaitingApiCounter());
		}
	};

	const handleOpenDrawer = (id) => {
		dispatch(incrementAwaitingApiCounter());
		getSubscriptionPlanById(id, userInfo.accessToken)
			.then((r) => {
				const { statusCode, data } = r;
				if (statusCode === 200) {
					setIsVisible(data.display);
					setDrawerData(data);
					dispatch(decrementAwaitingApiCounter());
					setOpen(!isOpen);
				}
			})
			.catch((err) => {
				setError(true);
			});
	};

	const handleCloseModal = () => {
		setIsModalActive(false);
	};

	useEffect(() => {
		dispatch(incrementAwaitingApiCounter());
		getSubscriptionPlan(userInfo.accessToken)
			.then((r) => {
				const { statusCode, data } = r;
				if (statusCode === 200) setSubscriptionPlans(data);
				dispatch(decrementAwaitingApiCounter());
			})
			.catch((err) => {
				setError(true);
				dispatch(decrementAwaitingApiCounter());
			});
	}, [isStateChange]);

	return (
		<>
			<Helmet>
				<title> Plans | BeyondCarts CMS </title>
			</Helmet>
			<div>
				<Box
					sx={{
						borderBottom: "1px solid #c4c4c4",
						paddingBottom: 2,
					}}
				>
					<Box
						sx={{
							display: "flex",
							marginTop: "20px",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Stack>
							<Typography variant="h4" sx={{ color: "#000" }}>
								Plans
							</Typography>
						</Stack>
						<AccountPopover />
					</Box>
					<TextField
						sx={{
							width: { xs: 240, sm: 300, md: "35%", lg: "25%" },
							boxShadow: "none",
							backgroundColor: "common.white",
							fontSize: { xs: 14, lg: 16 },
							lineHeight: "24px",
							marginTop: "10px",
							"& :nth-of-type(1)": {
								fontSize: { xs: 14, lg: 16 },
							},
							"& > div > input": {
								boxSizing: "border-box",
								color: "primary.main",
								height: { xs: 36, lg: 40 },
							},
							"& > div": {
								padding: 0,
							},
							"& .MuiOutlinedInput-root": {
								"&:hover fieldset": {
									borderColor: "primary.dark",
								},
								"&.Mui-focused fieldset": {
									borderColor: "primary.dark",
								},
							},
						}}
						variant="outlined"
						onChange={handleSearchPlan}
						placeholder="Search"
						type="text"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Button
										variant="contained"
										type="submit"
										sx={{
											boxShadow: "none",
											backgroundColor: "secondary.main",
											alignContent: "center",
											justifyContent: "center",
											borderTopLeftRadius: 0,
											borderBottomLeftRadius: 0,
											height: { xs: 36, lg: 40 },
											width: { sm: 50, lg: 60 },
											minWidth: 30,
											"& .MuiButton-startIcon": {
												margin: "0",
											},
										}}
										startIcon={
											<Iconify
												sx={{
													width: {
														lg: 20,
														md: 20,
														sm: 16,
														xs: 16,
													},
													height: "auto",
													margin: 0,
													color: "text.active",
												}}
												icon="eva:search-fill"
											/>
										}
									/>
								</InputAdornment>
							),
						}}
					/>
				</Box>
				<Typography
					variant="h5"
					sx={{ color: "black", padding: "10px 0", marginTop: 1 }}
				>
					Tenant Plans
				</Typography>
				<Typography
					variant="body2"
					sx={{ fontSize: 16, color: "black" }}
				>
					Add and remove tenant plans, adjust values and manage
					settings
				</Typography>
				<Box
					sx={{
						display: "flex",
						flexWrap: "wrap",
						width: "100%",
						flexDirection: {
							sm: "row",
							xs: "column",
						},
					}}
				>
					{error == true ? (
						<Box sx={{ color: "black" }}>No Subscription Plan</Box>
					) : (
						subscriptionPlans?.map((subscriptionPlan, index) => {
							return (
								<Card
									key={subscriptionPlan._id}
									onClick={() =>
										handleOpenDrawer(subscriptionPlan._id)
									}
									sx={{
										border: "1px solid #D6D6D6",
										cursor: "pointer",
										margin: "15px 20px 0px 0px",
										padding: "18px",
										width: {
											xs: "100%",
											md: "45%",
											lg: "30%",
										},
										height: "170px",
									}}
								>
									<Stack
										sx={{
											fontWeight: "bold",
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "space-between",
										}}
									>
										<Typography
											sx={{
												fontSize: "18px",
												fontWeight: "700",
												color: "black",
												padding: "10px 0 10px 0",
											}}
										>
											{subscriptionPlan.name}
										</Typography>

										<Box>
											<Stack
												sx={{
													display: "flex",
													flexDirection: "row",
													justifyContent:
														"space-between",
												}}
											>
												<Typography
													variant="body2"
													sx={{
														margin: "5px",
														color: `${
															subscriptionPlan.active
																? "green"
																: "red"
														}`,
													}}
												>
													Active
												</Typography>
												<Box
													sx={{
														margin: "7px 0px 0px 7px",
														height: "fit-content",
														padding: "8px",
														borderRadius: "50%",
														backgroundColor: `${
															subscriptionPlan.active
																? "green"
																: "red"
														}`,
													}}
												/>
											</Stack>

											<Stack
												sx={{
													display: "flex",
													flexDirection: "row",
													justifyContent:
														"space-between",
												}}
											>
												<Typography
													variant="body2"
													sx={{
														margin: "5px",
														color: `${
															subscriptionPlan?.display
																? "green"
																: "red"
														}`,
													}}
												>
													Visible
												</Typography>
												<Box
													sx={{
														margin: "7px 0px 0px 7px",
														height: "fit-content",
														padding: "8px",
														borderRadius: "50%",
														backgroundColor: `${
															subscriptionPlan.display ==
															true
																? "green"
																: "red"
														}`,
													}}
												/>
											</Stack>
										</Box>
									</Stack>
									<Stack
										sx={{
											margin: "12px 0px",
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
										}}
									>
										<FormLabel
											sx={{
												fontFamily: "LatoMedium",
												color: "black",
												fontSize: "15px",
												width: "160px",
											}}
										>
											Price Per Order Tracked:{" "}
										</FormLabel>
										<FormLabel
											sx={{
												fontFamily: "LatoMedium",
												color: "black",
												fontSize: "15px",
												paddingLeft: 1,
											}}
										>
											{`${subscriptionPlan.currency} ${
												subscriptionPlan.price_per_order
													? subscriptionPlan.price_per_order
													: "$0.00"
											}`}
										</FormLabel>
									</Stack>
									<Stack
										sx={{
											margin: "12px 0px",
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
										}}
									>
										<FormLabel
											sx={{
												fontFamily: "LatoMedium",
												color: "black",
												fontSize: "15px",
												width: "160px",
											}}
										>
											Price Per Return Order:{" "}
										</FormLabel>
										<FormLabel
											sx={{
												fontFamily: "LatoMedium",
												color: "black",
												fontSize: "15px",
												paddingLeft: 1,
											}}
										>
											{`${subscriptionPlan.currency} ${
												subscriptionPlan.price_per_return
													? subscriptionPlan.price_per_return
													: "$0.00"
											}`}
										</FormLabel>
									</Stack>
									<Stack
										sx={{
											margin: "14px 0px",
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
										}}
									>
										<FormLabel
											sx={{
												width: "100px",
												fontFamily: "LatoMedium",
												color: "black",
												fontSize: "15px",
											}}
										>
											Billing Cycle:{" "}
										</FormLabel>

										<FormLabel
											sx={{
												fontFamily: "LatoMedium",
												color: "black",
												fontSize: "15px",
												width: "50px",
											}}
										>
											{subscriptionPlan?.billing_cycle}
										</FormLabel>
									</Stack>
								</Card>
							);
						})
					)}
					{!searchPlan && (
						<Card
							onClick={() => {
								setIsModalActive(true);
								setPlanErrors({
									name: false,
									description: false,
									billingCycle: false,
									duration: false,
									pricePerOrder: false,
									subHeader: false,
								});
								setName("");
								setDescription("");
								setBillingCycle("");
								setPricePerOrder("");
								setPricePerReturn("")
								setDuration("");
								setSubHeader("");
							}}
							sx={{
								border: "1px solid #D6D6D6",
								margin: "20px 20px 0px 0px",
								padding: "18px",
								width: {
									xs: "100%",
									md: "45%",
									lg: "30%",
								},
								height: "170px",
								cursor: "pointer",
							}}
						>
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-around",
								}}
							>
								<Typography
									sx={{
										...textStyle,
										fontSize: "24px",
										fontWeight: "bold",
										textAlign: "center",
										margin: "45px 0px",
									}}
								>
									+
								</Typography>
							</Box>
						</Card>
					)}
					{isModalActive && (
						<Modal open={isModalActive} onClose={handleCloseModal}>
							<Box
								sx={{
									...popUpModalStyle,
									width: {
										xs: "85%",
										sm: "60%",
										md: "50%",
										lg: "40%",
									},
								}}
							>
								<Stack
									gap={5}
									direction="column"
									sx={{
										color: "common.black",
										padding: 4,
										width: "100%",
										fontFamily:
											typography.fontFamilyRegular,
										backgroundColor: "white",
									}}
								>
									<FormControl
										component="form"
										className="form"
										// onSubmit={handleSubmit(onSubmit)}
									>
										<IconButton
											aria-label="close"
											sx={{
												position: "fixed",
												top: { xs: 15, lg: 20 },
												right: {
													xs: 15,
													md: 20,
												},
												zIndex: 2,
											}}
											onClick={() =>
												setIsModalActive(false)
											}
										>
											<img
												width="30px"
												src="/assets/icons/table/close.svg"
												alt="X"
											/>
										</IconButton>
										<Stack spacing={2} alignItems="center">
											<Box
												style={{
													fontFamily:
														typography.fontFamilySemiBold,
												}}
												sx={{
													fontSize: {
														lg: 30,
														md: 27,
														xs: 27,
													},
													textAlign: "center",
												}}
											>
												Add Plan
											</Box>
										</Stack>

										<Stack spacing={2}>
											<Stack direction={"row"}>
												<TextField
													required="true"
													label="Name"
													name="name"
													value={name || ""}
													onChange={(event) => {
														setPlanErrors({
															...planErrors,
															name: false,
														});
														setName(
															event.target.value
														);
													}}
													sx={{
														width: "100%",
														"& .MuiFormHelperText-root":
															{
																marginLeft: 0,
																color: "red !important",
															},
														"& .MuiOutlinedInput-root":
															{
																"&:hover fieldset":
																	{
																		borderColor:
																			"primary.dark",
																	},
																"&.Mui-focused fieldset":
																	{
																		borderColor:
																			"primary.dark",
																	},
															},
													}}
													helperText={
														planErrors.name &&
														"Name is Required"
													}
												/>
											</Stack>
											<Stack>
												<TextField
													required="true"
													label="Sub Header"
													name="subHeader"
													value={subHeader}
													onChange={(event) => {
														setPlanErrors({
															...planErrors,
															subHeader: false,
														});
														setSubHeader(
															event.target.value
														);
													}}
													helperText={
														planErrors.subHeader &&
														"Sub Header is Required"
													}
													sx={{
														width: "100%",
														"& .MuiFormHelperText-root":
															{
																marginLeft: 0,
																color: "red !important",
															},
														"& .MuiInputBase-root":
															{
																"& .MuiOutlinedInput-input":
																	{
																		color: "black",
																	},
															},
														"& .MuiOutlinedInput-root":
															{
																"&:hover fieldset":
																	{
																		borderColor:
																			"primary.dark",
																	},
																"&.Mui-focused fieldset":
																	{
																		borderColor:
																			"primary.dark",
																	},
															},
													}}
												/>
											</Stack>
											<FormControl>
												<InputLabel id="demo-simple-select-helper-label">
													Billing Cycle *
												</InputLabel>
												<Select
													required="true"
													labelId="demo-simple-select-helper-label"
													id="demo-simple-select-helper"
													label="BillingCycle"
													value={billingCycle}
													sx={{
														"& .MuiFormHelperText-root":
															{
																marginLeft: 0,
																color: "red !important",
															},
														color: "common.black",
														"& .MuiOutlinedInput-root":
															{
																"&:hover fieldset":
																	{
																		borderColor:
																			"primary.dark",
																	},
																"&.Mui-focused fieldset":
																	{
																		borderColor:
																			"primary.dark",
																	},
															},
													}}
													onChange={(event) => {
														setPlanErrors({
															...planErrors,
															billingCycle: false,
														});
														setBillingCycle(
															event.target.value
														);
													}}
												>
													<MenuItem
														value="Weekly"
														sx={{
															color: "common.black",
														}}
													>
														Weekly
													</MenuItem>
													<MenuItem
														value="Monthly"
														sx={{
															color: "common.black",
														}}
													>
														Monthly
													</MenuItem>
													<MenuItem
														value="Yearly"
														sx={{
															color: "common.black",
														}}
													>
														Yearly
													</MenuItem>
												</Select>
												{planErrors.billingCycle && (
													<FormHelperText
														sx={{
															color: "red",
															margin: "5px 0 0",
														}}
													>
														Billing cycle is
														Required
													</FormHelperText>
												)}
											</FormControl>
											<Stack>
												<TextField
													required="true"
													label="Duration"
													name="duration"
													value={duration}
													onChange={(event) => {
														setPlanErrors({
															...planErrors,
															duration: false,
														});
														setDuration(
															event.target.value
														);
													}}
													helperText={
														planErrors.duration &&
														"Duration is Required"
													}
													sx={{
														width: "100%",
														"& .MuiFormHelperText-root":
															{
																marginLeft: 0,
																color: "red !important",
															},
														"& .MuiInputBase-root":
															{
																"& .MuiOutlinedInput-input":
																	{
																		color: "black",
																	},
															},
														"& .MuiOutlinedInput-root":
															{
																"&:hover fieldset":
																	{
																		borderColor:
																			"primary.dark",
																	},
																"&.Mui-focused fieldset":
																	{
																		borderColor:
																			"primary.dark",
																	},
															},
													}}
												/>
											</Stack>
											<Stack
												sx={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													columnGap: "30px",
													justifyContent:
														"space-between",
												}}
											>
												<TextField
													required="true"
													label="Price Per Order"
													name="pricePerOrder"
													value={pricePerOrder || ""}
													onChange={(event) => {
														setPlanErrors({
															...planErrors,
															pricePerOrder: false,
														});
														setPricePerOrder(
															event.target.value
														);
													}}
													helperText={
														planErrors.pricePerOrder &&
														"Price Per Order is Required"
													}
													sx={{
														flexGrow: 2,
														"& .MuiFormHelperText-root":
															{
																marginLeft: 0,
																color: "red !important",
															},
														"& .MuiOutlinedInput-root":
															{
																"&:hover fieldset":
																	{
																		borderColor:
																			"primary.dark",
																	},
																"&.Mui-focused fieldset":
																	{
																		borderColor:
																			"primary.dark",
																	},
															},
													}}
												/>
												<Stack
													sx={{
														display: "flex",
														flexDirection: "row",
														alignItems: "center",
													}}
												>
													<FormLabel
														sx={{
															fontFamily:
																"LatoMedium",
															color: "black",
															fontSize: "15px",
														}}
													>
														Visible:{" "}
													</FormLabel>
													<Switch
														onChange={(e) => {
															setCreateIsVisible(
																e.target.checked
															);
														}}
													></Switch>
												</Stack>
											</Stack>
											<Stack
												sx={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													columnGap: "30px",
													justifyContent:
														"space-between",
												}}
											>
												<TextField
													required="true"
													label="Price Per Return Order"
													name="pricePerReturn"
													value={pricePerReturn || ""}
													onChange={(event) => {
														setPlanErrors({
															...planErrors,
															pricePerReturn: false,
														});
														setPricePerReturn(
															event.target.value
														);
													}}
													helperText={
														planErrors.pricePerReturn &&
														"Price Per Order is Required"
													}
													sx={{
														flexGrow: 2,
														"& .MuiFormHelperText-root":
															{
																marginLeft: 0,
																color: "red !important",
															},
														"& .MuiOutlinedInput-root":
															{
																"&:hover fieldset":
																	{
																		borderColor:
																			"primary.dark",
																	},
																"&.Mui-focused fieldset":
																	{
																		borderColor:
																			"primary.dark",
																	},
															},
													}}
												/>
												{/* <Stack
													sx={{
														display: "flex",
														flexDirection: "row",
														alignItems: "center",
													}}
												>
													<FormLabel
														sx={{
															fontFamily:
																"LatoMedium",
															color: "black",
															fontSize: "15px",
														}}
													>
														Visible:{" "}
													</FormLabel>
													<Switch
														onChange={(e) => {
															setCreateIsVisible(
																e.target.checked
															);
														}}
													></Switch>
												</Stack> */}
											</Stack>
										</Stack>

										<Stack direction={"row"}>
											<TextField
												required="true"
												label="Description"
												multiline
												rows={4}
												rowsMax={8}
												value={description || ""}
												onChange={(event) => {
													setPlanErrors({
														...planErrors,
														description: false,
													});
													setDescription(
														event.target.value
													);
												}}
												sx={{
													width: "100%",
													"& .MuiFormHelperText-root":
														{
															marginLeft: 0,
															color: "red !important",
														},
													"& .MuiInputBase-root": {
														"& .MuiOutlinedInput-input":
															{
																color: "black",
															},
													},
													"& .MuiOutlinedInput-root":
														{
															"&:hover fieldset":
																{
																	borderColor:
																		"primary.dark",
																},
															"&.Mui-focused fieldset":
																{
																	borderColor:
																		"primary.dark",
																},
														},
												}}
												helperText={
													planErrors.description &&
													"Description is Required"
												}
											/>
										</Stack>
										<Stack
											spacing={2}
											direction={"row"}
											alignItems="center"
										>
											<Button
												fullWidth
												variant="contained"
												disableElevation
												// type="submit"
												sx={{
													boxShadow: "none",
													backgroundColor: "grey.600",
													paddingY: 1.3,
													fontSize: {
														md: 15,
														xs: 13,
													},
													"&:hover": {
														backgroundColor:
															"primary.lightIcon",
													},
												}}
												style={{
													fontFamily:
														typography.fontFamilySemiBold,
												}}
												onClick={() => {
													setIsModalActive(false);
												}}
											>
												Cancel
											</Button>
											<Button
												fullWidth
												variant="contained"
												disableElevation
												type="submit"
												sx={{
													boxShadow: "none",
													backgroundColor:
														palette.secondary.main,
													paddingY: 1.3,
													fontSize: {
														md: 15,
														xs: 13,
													},
												}}
												style={{
													fontFamily:
														typography.fontFamilySemiBold,
												}}
												onClick={handleCreatePlan}
											>
												Add Plan
											</Button>
										</Stack>
									</FormControl>
								</Stack>
							</Box>
						</Modal>
					)}
				</Box>
				{showSuccessModel && (
					<Modal
						open={showSuccessModel}
						onClose={() => setShowSuccessModel(false)}
					>
						<Box
							sx={{
								...popUpModalStyle,
								width: {
									xs: "80%",
									sm: "50%",
									md: "40%",
									lg: "25%",
								},
								height: { xs: "150px", md: "170px" },
								color: "black",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<IconButton
								aria-label="close"
								sx={{
									position: "fixed",
									top: { xs: 12, lg: 15 },
									right: {
										xs: 10,
										md: 15,
									},
									zIndex: 2,
								}}
								onClick={() => setShowSuccessModel(false)}
							>
								<img
									width="30px"
									src="/assets/icons/table/close.svg"
									alt="X"
								/>
							</IconButton>
							<FormLabel
								sx={{
									color: "black",
									fontWeight: "500",
									fontSize: "20px",
									marginBottom: "20px",
								}}
							>
								Plan created Successfully
							</FormLabel>
							<Button
								variant="contained"
								type="submit"
								sx={{
									boxShadow: "none",
									backgroundColor: palette.secondary.main,
									paddingY: 1.3,
									fontSize: {
										md: 15,
										xs: 13,
									},
									width: { xs: "150px", md: "200px" },
								}}
								style={{
									fontFamily: typography.fontFamilySemiBold,
								}}
								onClick={() => setShowSuccessModel(false)}
							>
								Done
							</Button>
						</Box>
					</Modal>
				)}
				{showUpdateModel && (
					<Modal
						open={showUpdateModel}
						onClose={() => setShowUpdateModel(false)}
					>
						<Box
							sx={{
								...popUpModalStyle,
								width: {
									xs: "80%",
									sm: "50%",
									md: "40%",
									lg: "25%",
								},
								height: { xs: "150px", md: "170px" },
								color: "black",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<IconButton
								aria-label="close"
								sx={{
									position: "fixed",
									top: { xs: 6, lg: 15 },
									right: {
										xs: 6,
										md: 15,
									},
									zIndex: 2,
								}}
								onClick={() => setShowUpdateModel(false)}
							>
								<img
									width="30px"
									src="/assets/icons/table/close.svg"
									alt="X"
								/>
							</IconButton>
							<FormLabel
								sx={{
									color: "black",
									fontWeight: "500",
									fontSize: "20px",
									marginBottom: "20px",
								}}
							>
								Plan Updated Successfully
							</FormLabel>
							<Button
								variant="contained"
								type="submit"
								sx={{
									boxShadow: "none",
									backgroundColor: palette.secondary.main,
									paddingY: 1.3,
									fontSize: {
										md: 15,
										xs: 13,
									},
									width: { xs: "150px", md: "200px" },
								}}
								style={{
									fontFamily: typography.fontFamilySemiBold,
								}}
								onClick={() => setShowUpdateModel(false)}
							>
								Done
							</Button>
						</Box>
					</Modal>
				)}
				{isOnDelete && (
					<Modal
						open={isOnDelete}
						onClose={() => setIsOnDelete(false)}
					>
						<Box
							sx={{
								...popUpModalStyle,
								width: "32%",
								color: "black",
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-around",
								padding: "20px",
							}}
						>
							<IconButton
								aria-label="close"
								sx={{
									position: "fixed",
									top: { xs: 15, lg: 20 },
									right: {
										xs: 15,
										md: 20,
									},
									zIndex: 2,
								}}
								onClick={() => setIsOnDelete(false)}
							>
								<img
									width="30px"
									src="/assets/icons/table/close.svg"
									alt="X"
								/>
							</IconButton>
							<FormLabel
								sx={{
									color: "black",
									fontWeight: "500",
									textAlign: "center",
									padding: "20px 0",
									fontSize: "20px",
								}}
							>
								Are you sure?
							</FormLabel>
							<FormLabel
								sx={{
									color: "#676767",
									textAlign: "center",
									paddingBottom: "30px",
									fontSize: "14px",
								}}
							>
								Do you really want to delete this subscription
								plan?
							</FormLabel>
							<Stack
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-evenly",
									width: "100%",
								}}
							>
								<Button
									variant="contained"
									type="submit"
									sx={{
										boxShadow: "none",
										backgroundColor: "#676767",
										paddingY: 1.3,
										fontSize: {
											lg: 15,
											md: 15,
											xs: 13,
											sm: 13,
										},
									}}
									style={{
										fontFamily:
											typography.fontFamilySemiBold,
									}}
									onClick={() => setIsOnDelete(false)}
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									type="submit"
									sx={{
										boxShadow: "none",
										backgroundColor: palette.secondary.main,
										paddingY: 1.3,
										fontSize: {
											lg: 15,
											md: 15,
											xs: 13,
											sm: 13,
										},
									}}
									style={{
										fontFamily:
											typography.fontFamilySemiBold,
									}}
									onClick={handleDeletePlan}
								>
									Continue
								</Button>
							</Stack>
						</Box>
					</Modal>
				)}
				{showDeleteModel && (
					<Modal
						open={showDeleteModel}
						onClose={() => setShowDeleteModel(false)}
					>
						<Box
							sx={{
								...popUpModalStyle,
								width: "30%",
								height: "180px",
								color: "black",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<IconButton
								aria-label="close"
								sx={{
									position: "fixed",
									top: { xs: 15, lg: 20 },
									right: {
										xs: 15,
										md: 20,
									},
									zIndex: 2,
								}}
								onClick={() => setShowDeleteModel(false)}
							>
								<img
									width="30px"
									src="/assets/icons/table/close.svg"
									alt="X"
								/>
							</IconButton>
							<FormLabel
								sx={{
									color: "black",
									fontWeight: "500",
									fontSize: "20px",
									marginBottom: "20px",
								}}
							>
								Plan Deleted Successfully
							</FormLabel>
							<Button
								variant="contained"
								type="submit"
								sx={{
									boxShadow: "none",
									backgroundColor: palette.secondary.main,
									paddingY: 1.3,
									fontSize: {
										lg: 15,
										md: 15,
										xs: 13,
										sm: 13,
									},
								}}
								style={{
									fontFamily: typography.fontFamilySemiBold,
								}}
								onClick={() => setShowDeleteModel(false)}
							>
								Done
							</Button>
						</Box>
					</Modal>
				)}
				{isOpen && (
					<Drawer
						anchor="right"
						open={isOpen}
						onClose={() => [
							setOpen(false),
							setUpdatePlanErrors({}),
						]}
					>
						<IconButton
							aria-label="close"
							sx={{
								position: "fixed",
								top: { xs: 15, lg: 20 },
								right: {
									xs: 15,
									md: 20,
								},
								zIndex: 2,
							}}
							onClick={() => [
								setOpen(false),
								setUpdatePlanErrors({}),
							]}
						>
							<img
								width="30px"
								src="/assets/icons/table/close.svg"
								alt="X"
							/>
						</IconButton>
						<Box
							sx={{
								width: {
									xs: "300px",
									sm: "400px",
									md: "480px",
								},
								color: "#000",
								padding: "40px",
								marginTop: "20px",
							}}
						>
							<Stack
								sx={{
									width: "100%",
									padding: "10px, 20px, 10px, 20px",
									borderRadius: "10px",
									// backgroundColor: "#ECECEC",
								}}
							>
								<TextField
									value={drawerData.name}
									onChange={(e) => {
										setDrawerData({
											...drawerData,
											name: e.target.value,
										});
									}}
									sx={{
										"& .MuiInputBase-input": {},
										"& .MuiInputBase-root": {
											color: "black",
											fontWeight: "bold",
											fontSize: "16px",
											height: "40px",
											marginTop: "20px",
											"&:hover fieldset": {
												borderColor: "#CECECE",
											},
											"&.Mui-focused fieldset": {
												borderColor: "#CECECE",
											},
										},
										borderRadius: "8px",
									}}
								/>
								{updatePlanErrors.name && (
									<FormLabel
										sx={{
											fontSize: "12px",
											color: "red",
											padding: "2px 2px 0",
										}}
									>
										Name is required
									</FormLabel>
								)}
							</Stack>
							<Stack sx={{ margin: "10px 0px 30px 0px" }}>
								<Stack
									sx={{
										margin: "25px 0px",
										fontWeight: "bold",
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}}
								>
									<FormLabel
										sx={{
											fontFamily: "LatoMedium",
											color: "black",
											fontSize: "15px",
											fontWeight: "bold",
											padding: "10px, 20px, 10px, 20px",
											gap: "10px",
											color: "black",
											// fontSize: "14px"
										}}
									>
										Status:{" "}
									</FormLabel>
									{drawerData.active ? (
										<FormLabel
											sx={{
												marginLeft: "10px",
												fonntSize: "13px",
												color: "#299D4A",
												fontWeight: "bold",
											}}
										>
											Enabled
										</FormLabel>
									) : (
										<FormLabel
											sx={{
												marginLeft: "10px",
												fonntSize: "13px",
												color: "red",
												fontWeight: "bold",
											}}
										>
											Disabled
										</FormLabel>
									)}
								</Stack>
								<Stack
									sx={{
										marginBottom: "10px",
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}}
								>
									<FormLabel
										sx={{
											fontFamily: "LatoMedium",
											color: "black",
											fontSize: "15px",
										}}
									>
										Price Per Order Tracked:{" "}
									</FormLabel>
									<TextField
										sx={{
											"& .MuiInputBase-input": {
												textAlign: "center",
											},
											"& .MuiInputBase-root": {
												color: "black",
												fontWeight: "bold",
												fontSize: "12px",
												height: "30px",
												margin: "0 10px",
												width: "70px",
												"&:hover fieldset": {
													borderColor: "#CECECE",
												},
												"&.Mui-focused fieldset": {
													borderColor: "#CECECE",
												},
											},
											borderRadius: "8px",
										}}
										value={`${drawerData.price_per_order}`}
										onChange={(event) => {
											setDrawerData({
												...drawerData,
												price_per_order:
													event.target.value,
											});
										}}
										// defaultValue={`${drawerData.currency} ${drawerData.price_per_order}`}
									/>
									{updatePlanErrors.price_per_order && (
										<FormLabel
											sx={{
												fontSize: "12px",
												color: "red",
												padding: "2px 2px 0",
											}}
										>
											Price is required
										</FormLabel>
									)}
								</Stack>
								<Stack
									sx={{
										marginBottom: "10px",
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}}
								>
									<FormLabel
										sx={{
											fontFamily: "LatoMedium",
											color: "black",
											fontSize: "15px",
										}}
									>
									Price Per Return Order:{" "}
									</FormLabel>
									<TextField
										sx={{
											"& .MuiInputBase-input": {
												textAlign: "center",
											},
											"& .MuiInputBase-root": {
												color: "black",
												fontWeight: "bold",
												fontSize: "12px",
												height: "30px",
												margin: "0 10px",
												width: "70px",
												"&:hover fieldset": {
													borderColor: "#CECECE",
												},
												"&.Mui-focused fieldset": {
													borderColor: "#CECECE",
												},
											},
											borderRadius: "8px",
										}}
										value={`${drawerData.price_per_return}`}
										onChange={(event) => {
											setDrawerData({
												...drawerData,
												price_per_return:
													event.target.value,
											});
										}}
										// defaultValue={`${drawerData.currency} ${drawerData.price_per_return}`}
									/>
									{updatePlanErrors.price_per_return && (
										<FormLabel
											sx={{
												fontSize: "12px",
												color: "red",
												padding: "2px 2px 0",
											}}
										>
											Price is required
										</FormLabel>
									)}
								</Stack>
								<Stack
									sx={{
										marginBottom: "10px",
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}}
								>
									<FormLabel
										sx={{
											fontFamily: "LatoMedium",
											color: "black",
											fontSize: "15px",
										}}
									>
										Billing Cycle:{" "}
									</FormLabel>
									<Select
										sx={{
											color: "black",
											fontWeight: "bold",
											margin: "0 10px",
											width: "100px",
											height: "30px",
											borderRadius: "8px",
											fontSize: "12px",
										}}
										defaultValue={drawerData.billing_cycle}
										onChange={(event) =>
											setDrawerData({
												...drawerData,
												billing_cycle:
													event.target.value,
											})
										}
									>
										<MenuItem
											sx={{
												fontSize: "10px",
												color: "black",
											}}
											value={"Weekly"}
										>
											Weekly
										</MenuItem>
										<MenuItem
											sx={{
												fontSize: "10px",
												color: "black",
											}}
											value={"Monthly"}
										>
											Monthly
										</MenuItem>
										<MenuItem
											sx={{
												fontSize: "10px",
												color: "black",
											}}
											value={"Yearly"}
										>
											Yearly
										</MenuItem>
									</Select>
								</Stack>
								<Stack
									sx={{
										marginBottom: "10px",
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}}
								>
									<FormLabel
										sx={{
											fontFamily: "LatoMedium",
											color: "black",
											fontSize: "15px",
										}}
									>
										Duration:{" "}
									</FormLabel>
									<TextField
										sx={{
											"& .MuiInputBase-input": {
												textAlign: "center",
											},
											"& .MuiInputBase-root": {
												color: "black",
												fontWeight: "bold",
												fontSize: "12px",
												height: "30px",
												margin: "0 10px",
												width: "70px",
												"&:hover fieldset": {
													borderColor: "#CECECE",
												},
												"&.Mui-focused fieldset": {
													borderColor: "#CECECE",
												},
											},
											borderRadius: "8px",
										}}
										value={`${drawerData.duration}`}
										onChange={(event) => {
											setDrawerData({
												...drawerData,
												duration: event.target.value,
											});
										}}
										// defaultValue={`${drawerData.currency} ${drawerData.price_per_order}`}
									/>
									{updatePlanErrors.duration && (
										<FormLabel
											sx={{
												fontSize: "12px",
												color: "red",
												padding: "2px 2px 0",
											}}
										>
											Duration is required
										</FormLabel>
									)}
								</Stack>
								<Stack
									sx={{
										marginBottom: "10px",
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}}
								>
									<FormLabel
										sx={{
											fontFamily: "LatoMedium",
											color: "black",
											fontSize: "15px",
										}}
									>
										Visible:{" "}
									</FormLabel>
									<Switch
										checked={isVisible}
										onChange={() =>
											setIsVisible(!isVisible)
										}
									></Switch>
								</Stack>
							</Stack>
							<Stack sx={{ margin: "5px 0px 30px 0px" }}>
								<Stack sx={{ margin: "5px 0px" }}>
									<FormLabel
										sx={{
											// gap: "18px",
											fontFamily: "LatoMedium",
											color: "black",
											fontSize: "15px",
											fontWeight: "bold",
										}}
									>
										Description:
									</FormLabel>
								</Stack>
								<Stack
									sx={{
										height: "175px",
										width: "100%",
										// padding: "10px, 20px, 10px, 20px",
										borderRadius: "10px",
										// backgroundColor: "#ECECEC",
									}}
								>
									<TextField
										multiline
										rows={2}
										rowsMax={4}
										value={drawerData.description}
										onChange={(event) => {
											setDrawerData({
												...drawerData,
												description: event.target.value,
											});
										}}
										sx={{
											"& .MuiInputBase-root": {
												"&:hover fieldset": {
													borderColor: "primary.dark",
												},
												"& .MuiInputBase-input": {
													height: `120px !important`,
												},
												"& .MuiOutlinedInput-input": {
													color: "black",
												},
												"& fieldset": {
													// border: '0px',
												},
											},
											// height: "175px",
											// padding: "10px",
											fontSize: "15px",
										}}
									></TextField>
									{updatePlanErrors.description && (
										<FormLabel
											sx={{
												fontSize: "12px",
												color: "red",
												padding: "2px 2px 0",
											}}
										>
											Description is required
										</FormLabel>
									)}
								</Stack>
							</Stack>
							<Stack sx={{ marginTop: "30px" }}>
								<FormLabel
									sx={{
										fontFamily: "LatoMedium",
										color: "black",
										fontSize: "15px",
										fontWeight: "bold",
									}}
								>
									Sub Header:
								</FormLabel>
							</Stack>
							<Stack sx={{ margin: "10px 0px" }}>
								<TextField
									value={drawerData.sub_header}
									onChange={(event) => {
										setDrawerData({
											...drawerData,
											sub_header: event.target.value,
										});
									}}
									sx={{
										"& .MuiInputBase-root": {
											"& .MuiOutlinedInput-input": {
												color: "black",
											},
											"&:hover fieldset": {
												borderColor: "#CECECE",
											},
											"&.Mui-focused fieldset": {
												borderColor: "#CECECE",
											},
										},
									}}
								/>
								{updatePlanErrors.sub_header && (
									<FormLabel
										sx={{
											fontSize: "12px",
											color: "red",
											padding: "2px 2px 0",
										}}
									>
										Sub header is required
									</FormLabel>
								)}
							</Stack>
							<Stack sx={{ marginTop: "30px" }}>
								<Button
									onClick={handleUpdatePlan}
									sx={{ height: "40px", margin: "10px 0px" }}
									variant="contained"
								>
									Update Plan Criteria
								</Button>
								{drawerData.active ? (
									<Button
										onClick={handleDisablePlan}
										sx={{
											height: "40px",
											margin: "10px 0px",
										}}
										variant="outlined"
										color="error"
									>
										Disable Plan
									</Button>
								) : (
									<Button
										onClick={handleEnablePlan}
										sx={{
											color: "green",
											height: "40px",
											margin: "10px 0px",
										}}
										variant="outlined"
									>
										Enable Plan
									</Button>
								)}
								<DeleteButton
									onClick={() => {
										setIsOnDelete(true);
									}}
									sx={{ height: "40px", margin: "10px 0px" }}
									variant="contained"
								>
									Delete Plan
								</DeleteButton>
							</Stack>
						</Box>
					</Drawer>
				)}
			</div>
		</>
	);
};

export default SubscriptionPlans;
