import getData from "src/dataFetcher/getDataFetcher";
import putData from "src/dataFetcher/putDataFetcher";

export async function getAllTenants(token) {
	const get_tenants_url =
		process.env.REACT_APP_BACKEND_BASE_URL +
		process.env.REACT_APP_TENANT_ROUTES;
	return await getData(get_tenants_url,{},{},token);
}
export async function getTenantById(tenant_id,token) {
	const get_tenantID_url = `${
		process.env.REACT_APP_BACKEND_BASE_URL +
		process.env.REACT_APP_TENANT_BY_ID_ROUTE
	}${tenant_id}`;
	return await getData(get_tenantID_url,{},{},token);
}


export async function updateTenant(
	data,
	userInfo,
	headers
  ) {
	const token = userInfo?.accessToken;
	const tenant_id = headers?.tenant_id; 
  	const get_tenantID_url = `${
		process.env.REACT_APP_BACKEND_BASE_URL +
		process.env.REACT_APP_TENANT_BY_ID_ROUTE
	}${tenant_id}`;
	return await putData(
	get_tenantID_url,
	  headers,
	  data,
	  token
	);
  }